import logo from './logo.svg';
import mock from './mock.svg';
import text from './text.svg';
import tick from './tick.svg';
import './App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  const [email, setEmail] = useState("")
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLaoding] = useState(false)
  const [showListView, setEmailListView] = useState(false)
  const [showError, setShowError] = useState("")
  const [clickCount, setClickCount] = useState(0)
  const [emailsList, setEmailsList] = useState([])

  const handleEmailChange = (email) => {
    setEmail(email)
  }


  useEffect(() => {
    if (clickCount > 15) {
      setEmailListView(true)
      axios.get('https://dev-api.boutstream.com/public/list-emails')
        .then(response => {
          setEmailsList(response.data.data)
        })
    }
  }, [clickCount])

  const submitHandler = () => {
    setIsLaoding(true)
    if (!email) {
      setIsLaoding(false)
      setShowError("Please enter email");
      return
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setIsLaoding(false)
      setShowError("Please enter corrent email")
      return
    }
    axios.post('https://dev-api.boutstream.com/public/add-email', { "email": email })
      .then(response => {
        setIsSuccess(true)
        setIsLaoding(false)
      })
      .catch(error => {
        console.error("Error:", error.response.data.message);
        setShowError(error.response.data.message || "Something technical issue happens, please try again later.")
        setIsSuccess(false);
        setIsLaoding(false);
      });
  }
  const handleUserKeyPress = () => {
    setClickCount((prev) => prev + 1)
  }

  return (
    <>
      {!showListView ?
        <div className="app">
          <div className="app-wrapper">
            <div className="logo-wrapper" onClick={handleUserKeyPress} >
              <img src={logo} alt="" />
            </div>
            <div className="main-wrapper">
              <div className="left-wrapper">
                <div className="main-heading">
                  <h2>Tracking <br />Wrestling <br /> made easier</h2>
                </div>
                <div className='input-email-wrapper'>
                  {
                    isSuccess ?
                      <div className='success-wrapper'>
                        <img src={tick} alt="" />
                        <h3>Thank you for signing up for early access! Stay tuned for updates as we prepare something amazing for you.</h3>
                      </div>
                      :
                      <>
                        {/* <label htmlFor="">Be the First to Know</label> */}
                        <input type="email" onChange={(e) => { handleEmailChange(e.target.value); setShowError("") }} placeholder='Enter Email' />
                        {showError && <p className='error'>{showError}</p>}
                        <button onClick={submitHandler} disabled={isLoading}>Be the First to Know</button>
                      </>
                  }

                </div>
              </div>
              <div className="right-wrapper">
                <div className="text-wrapper">
                  <img src={text} alt="" />
                </div>
                <div className="mock-wrapper">
                  <img src={mock} alt="" className='mock' />
                </div>
              </div>
            </div>
          </div>
        </div> :
        <div className="emaillistiing">
          <ul>
            {
              emailsList.map((email) => {
                return <li>{email.email}</li>
              })
            }
          </ul>
        </div>
      }
    </>
  );
}

export default App;
